import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import useTopArticles from "../hooks/useTopArticles"

const IndexPage = ({ data }) => {
  const posts = useTopArticles()

  return (
    <Layout>
      <SEO title="Home" />

      <div className="font-heading mt-32">
        <div>
          <span
            className="text-9xl tracking-tight font-bold inline-block mb-5 bg-gradient-to-r from-blue-600 via-red-500 to-purple-600 bg-clip-text text-transparent"
            style={{ "-webkit-background-clip": "text" }}
          >
            hello.
          </span>
        </div>
        <div>
          <span className="text-5xl tracking-tight">
            I’m Allan Bond, front-end architect and engineer.
          </span>
        </div>
      </div>

      <div className="mt-32">
        {posts.map(({ node }, index, array) => {
          const title = node.frontmatter.title
          return (
            <article className="mt-5">
              <Link to={node.frontmatter.path}>
                <span className="font-heading text-2xl">{title}</span>
              </Link>
              <div>{node.frontmatter.description}</div>
            </article>
          )
        })}
      </div>
    </Layout>
  )
}

export default IndexPage
